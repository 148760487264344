<template>
	<div class="weather">
	    <div class="daily">
			<img class="img" src="@/assets/images/weather.png" alt="">
			<span>{{langset.irradiation}}</span>
			<p><span>{{stationInfo.irradiance || "-"}}</span>w/㎡</p>
	    </div>
	    <div class="other">
	            <div class="cell-box">
	                <div class="left">{{langset.irradiation}}</div>
	                <div class="right"><span>{{stationInfo.humidness || "0"}}</span>%</div>
	            </div>
	            <div class="cell-box">
	                <div class="left">{{langset.temperature}}</div>
	                <div class="right"><span>{{stationInfo.temperature || "0"}}</span>℃</div>
	            </div>
				<div class="cell-box">
				    <div class="left">{{langset.windspeed}}</div>
				    <div class="right"><span>{{stationInfo.wind_speed || "0"}}</span>m/s</div>
				</div>
	    </div>
	</div>
    <!-- <div class="weather">
        <div>
            <div class="icon">
                <img src="@/assets/images/weather.png" alt="">
            </div>
            <div class="information">
                <p><span>{{stationInfo.irradiance || "-"}}</span>w/㎡</p>
                <span>{{langset.irradiation}}</span>
            </div>
            <div class="information">
                <p><span>{{stationInfo.humidness || "-"}}%</span></p>
                <span>{{langset.humidity}}</span>
            </div>
            <div class="information">
                <p><span>{{stationInfo.temperature || "-"}}℃</span></p>
                <span>{{langset.temperature}}</span>
            </div>
            <div class="information">
                <p><span>{{stationInfo.wind_speed || "-"}}</span>m/s</p>
                <span>{{langset.windspeed}}</span>
            </div>
        </div>
    </div> -->
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
	export default {
		name: 'homeTab',
		mixins: [mixin],
		data() {
			return {
				stationInfo:{}
			}
		},
		methods: {
			// 跳转页面
			onClickRight(page) {
				this.$router.push({
					name: page
				})
			},
			// 获取头部数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-meteorological-monitoring-real", // 接口code
						"@station_id": localStorage.getItem("stationPvId") //  城市名称
					})
					.then((res) => {
						console.log(res, ',,,,,,,,,,,,,,,,,,,,,,')
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>
<style lang="scss" scoped>
    .weather {
        padding: 0 12px;
        margin-top: 12px;
        display: flex;
		justify-content: space-between;
        >div:first-child {
            margin-right: 12px;
        }
        .daily {
            padding: 12px;
			width: 49%;
            border-radius: 16px 16px 16px 16px;
            background: rgba(77, 211, 208, 0.2);
			color: rgba(0, 0, 0, 0.70);
			background-image: url('@/assets/images/weatherbg.png');
			background-size: 100%; /* 或者 100% 100% */
			background-repeat: no-repeat; /* 确保不重复 */
			background-position: 50% 100%; /* 确保图片居中 */
			position: relative;
			padding-left: 80px;
			
			.img{
				position: absolute;
				left: 10px;
				top: 5px;
				width: 60px;
				height: 60px;
			}
			span{
				font-size: 18px;
			}
            p {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
				height: 24px;
                span {
					color: #000000;
                    font-size: 18px;
                    line-height: 36px;
					font-weight: 600;
                    margin-right: 4px;
                }
            }
        }
        .other {
			display: flex;
            flex-direction: column;
			width: 49%;
			background-color: #ffffff;
			border-radius: 16px 16px 16px 16px;
			padding: 10px 0;
			.cell-box{
				padding: 4px 15px;
				display: flex;
				color: rgba(0, 0, 0, 0.70);
				.left{
					flex: 1;
				}
				.right{
					flex: 1;
					text-align: right;
					span{
						color: rgba(0, 0, 0, 0.90);
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
		}
    }
</style>