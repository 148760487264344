let lang = {
	EN: {
		work: "work",
		searchKey: "Please enter search keywords",
		day: "D",
		operation: "Operation",
		region: "Region",
		Operationdate: "Operation date",
		SiteDetails: "SiteDetails",
		Dailyearnings: "Day",
		Monthlyincome: "Month",
		Annualincome: "Annual",
		Accumulatedincome: "Accumulated",
		tenthousand: "T K",
		totalroi:  "Total roi",
		Totalrevenue: "Total revenue",
		Totalinvestmentamount: "Total investment",
		Totalchargingcapacity: "Total charging",
		Totaldischargecapacity: "Total discharge",
		Totalpowergeneration: "Total power",
		Realtimepower: "Realtimepower",
		Opticalstorageequipment: "Optical storage equipment",
		Totalenergystoragecapacity: "Energy",
		charge: "charge",
		chargePrice: "chargePrice",
		static: "static",
		discharge: "dis",
		dischargePrice: "dischargePrice",
		electricitygeneration: "electricity",
		fault: "fault",
		Totalnumberofphotovoltaics: "INV",
		day: "day",
		month: "month",
		year: "year",
		more: "more",
		profit: "profit",
		unit: "unit",
		Socialcontribution: "Social contribution",
		Socialcontribution: "Socialcontribution",
		Savestandardcoal: "Save coal",
		CO2carbonreduction: "CO2 reduction",
		Equivalenttreeplantingamount: "Equivalent tree",
		ton: "ton",
		ke: "",
		search: "search",
		searchTips: "Please enter keywords",
		Belongingcompany: "Belonging company",
		Powerstationtype: "Power station type",
		Operationmode: "Operation mode",
		Operationstatus: "Operation status",
		region: "region",
		geographicalposition: "geographical position",
		longitude: "longitude",
		latitude: "latitude",
		Energystorageinstalledcapacity: "Energy storage installed capacity",
		Photovoltaicinstalledcapacity: "Photovoltaic installed capacity",
		Operationdate: "Operation date",
		Electricityclassification: "Electricity classification",
		Voltagesystem: "Voltage system",
		Voltagelevel: "Voltage level",
		siteDetailsPage: "Station",
		searchPage: "Search",
		Dailychargingcapacity: "Daily charging capacity",
		Dailydischargecapacity: "Daily discharge capacity",
		Totalinstalledcapacity: "Total installed capacity",
		Yuan: "Yuan/kwh",
		irradiation: "TSI",
		humidity: "RH",
		temperature: "TEMP",
		windspeed: "WS",
		Dailyelectricitygeneration: "Daily electricity generation",
		electricitygeneration: "electricity",
		yen: "yuan",
		Todaymaximumpower: "Today's maximum power",
		power: "power",
		Accumulatedpowergeneration: "Accumulated power",
		Monthlyelectricity: "Monthly electricity",
		Annualelectricity: "Annual electricity",
	},
	CN:{
		work: "工作",
		searchKey: "请输入关键字",
		day: "天",
		operation: "安全运行",
		region: "地区",
		Operationdate: "投运日期",
		SiteDetails: "站点详情",
		Dailyearnings: "当日收益",
		Monthlyincome: "当月收益",
		Annualincome: "当年收益",
		Accumulatedincome: "累计收益",
		tenthousand: "万",
		totalroi: "总投资回报率",
		Totalrevenue: "总收益",
		Totalinvestmentamount: "投资总额",
		Totalchargingcapacity: "总充电量",
		Totaldischargecapacity: "总放电量",
		Totalpowergeneration: "总发电量",
		Realtimepower: "实时功率",
		Opticalstorageequipment: "光储设备",
		Totalenergystoragecapacity: "储能总数",
		charge: "充电",
		chargePrice: "充电单价",
		static: "静止",
		discharge: "放电",
		dischargePrice: "放电单价",
		electricitygeneration: "发电",
		fault: "故障",
		Totalnumberofphotovoltaics: "逆变器",
		day: "日",
		month: "月",
		year: "年",
		more: "更多",
		profit: "收益",
		unit: "单位",
		Socialcontribution: "社会贡献",
		Savestandardcoal: "节约标准煤",
		CO2carbonreduction: "CO2碳减排",
		Equivalenttreeplantingamount: "等效植树量",
		ton: "吨",
		ke: "棵",
		search: "搜索",
		Belongingcompany: "所属企业",
		Powerstationtype: "电站类型",
		Operationmode: "运营模式",
		Operationstatus: "运营状态",
		region: "区域",
		geographicalposition: "地理位置",
		longitude: "经度",
		latitude: "纬度",
		Energystorageinstalledcapacity: "储能装机容量",
		Photovoltaicinstalledcapacity: "光伏装机容量",
		Operationdate: "投运日期",
		Electricityclassification: "用电分类",
		Voltagesystem: "电压制度",
		Voltagelevel: "电压等级",
		siteDetailsPage: "站点详情",
		searchPage: "搜索",
		Dailychargingcapacity: "日充电量",
		Dailydischargecapacity: "日放电量",
		Totalinstalledcapacity: "总装机容量",
		Yuan: "元/度",
		irradiation: "辐照度",
		humidity: "湿度",
		temperature: "温度",
		windspeed: "风速",
		Dailyelectricitygeneration: "日发电量",
		electricitygeneration: "发电量",
		yen: "元",
		Todaymaximumpower: "今日最大功率",
		power: "功率",
		Accumulatedpowergeneration: "累计发电量",
		Monthlyelectricity: "当月发电量",
		Annualelectricity: "当年发电量",
	}
}

export default lang;