<template>
    <div class="daily-earnings-box-pv">
        <div class="daily">
            <p>{{langset.Dailyearnings}}</p>
            <p><span>{{stationInfo.today_income || "0"}}</span>{{stationInfo.today_income_unit}}</p>
            <div class="daily-charts" ref="charts">
                <!-- 单日收益图表 -->
            </div>
        </div>
        <div class="other">
            <div>
                <div>
                    <div>{{langset.Monthlyincome}}</div>
                    <p><span>{{stationInfo.month_income || "0"}}</span>{{stationInfo.month_income_unit}}</p>
                </div>
                <div>
                    <div>{{langset.Annualincome}}</div>
                    <p><span>{{stationInfo.year_income || "0"}}</span>{{stationInfo.year_income_unit}}</p>
                </div>
            </div>
            <div>
                <div>{{langset.Accumulatedincome}}</div>
                <p>
					<span>{{stationInfo.total_income || "0"}}</span>
					<span class="span">{{stationInfo.total_income_unit}}</span>
				</p>
            </div>
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
    export default {
		mixins: [mixin],
        data() {
            return {
				stationInfo: {},
                option: {
                    grid: {
                        left: '0%',
                        right: '0%',
                        top: '0%',
                        bottom: '0%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                        type: 'category',
                        boundaryGap: false,
                        data: []
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value',
                        axisLabel: false,
                        splitLine: {
                            show: false
                        }
                        }
                    ],
                    series: [
                        {
                        name: 'Line 1',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 2,
                            color: '#fff',
                            opacity: .25
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: .15,
                            color: '#fff'
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [140, 232, 101, 264, 90, 340, 250]
                        }
                    ]
                }
            }
        },
        methods: {
			// 初始化echart
            initChart() {
                let myEcharts = this.$echarts.init(this.$refs.charts)
                myEcharts.setOption(this.option)
            },
			// 获取数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "solar-overview-earnings-overview", // 接口code
						"@station_id": localStorage.getItem('stationPvId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						console.log(res)
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
        },
        mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
            this.initChart()
			this.getData()
        },
    }
</script>
<style lang="scss" scpoed>
    .daily-earnings-box-pv {
        padding: 0 12px;
        margin-top: 12px;
        display: flex;
		justify-content: space-between;
        >div:first-child {
            margin-right: 12px;
        }
        .daily {
            padding: 12px 6px;
			width: 49%;
            background: #00ADB5;
            border-radius: 16px 16px 16px 16px;
            p {
                display: flex;
                align-items: end;
                color: rgba(255,255,255,0.95);
                margin-bottom: 4px;
				word-break: break-all;
                span {
                    font-size: 30px;
                    line-height: 32px;
                    margin-right: 2px;
                }
            }
            .daily-charts {
                height: 100%;
                padding-top: 30%;
            }
        }
        .other {
            display: flex;
            flex-direction: column;
			width: 49%;
            > div {
                flex: 1;
                padding: 12px;
                flex-direction: column;
                justify-content: space-between;
            }
            > div:first-child {
                margin-bottom: 12px;
                background: #E8FFF0;
                box-shadow: inset 0px 0px 1px 0px #AFF0C7;
                border-radius: 16px 16px 16px 16px;
                div {
                    color: rgba(0,0,0,0.7);
                    > div {
                        flex: 1;
						margin-bottom: 4px;
                    }
                    span {
                        color: #000;
                        font-weight: 800;
                    }
                }
            }
            > div:last-child {
                background: #E8FFFC;
                box-shadow: inset 0px 0px 1px 0px rgba(77,211,208,0.2);
                border-radius: 16px 16px 16px 16px;
                p {
                    display: flex;
                    line-height: 18px;
					.span{
						font-size: 16px;
					}
                }
                span {
                    color:#000;
                    font-size: 20px;
                    line-height: 20px;
                }
				
            }
        }
    }
</style>