
 
 //  minxin.js
const mixin = {
  data() {
    return{
      langset:{}
    }
  },
  created() {},
  mounted() {
  	// 初始化语言
  	this.lang = localStorage.getItem("lang") ?localStorage.getItem("lang") : 'CN'
  	
  }
}
export default mixin