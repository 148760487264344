<template>
    <div class="pv-Device-box">
        <div class="title">{{langset.Opticalstorageequipment}}</div>
        <div class="pv-list">
            <div>
                <div class="pv-list-img">
                    <img src="@/assets/images/energyStorage.png" alt="">
                </div>
                <div class="pv-list-info">
                    <div class="pv-info-total">
                        <p>{{langset.Totalenergystoragecapacity}}</p>
                        <div>{{stationInfo.total_num || '-'}}<span>个</span></div>
                    </div>
                    <div class="pv-type-list">
                        <div>
                            <div class="charge"></div>
                            <p>{{langset.charge}}</p>
                            <span>{{stationInfo.chg_num || '-'}}</span>
                        </div>
                        <div>
                            <div class="stationary"></div>
                            <p>{{langset.static}}</p>
                            <span>{{stationInfo.static_num || '-'}}</span>
                        </div>
                    </div>
                    <div class="pv-type-list">
                        <div>
                            <div class="discharge"></div>
                            <p>{{langset.discharge}}</p>
                            <span>{{stationInfo.dischg_num || '-'}}</span>
                        </div>
                        <div>
                            <div class="fault"></div>
                            <p>{{langset.fault}}</p>
                            <span>{{stationInfo.breakdown_num || '-'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
	export default {
		name: 'pvDevice',
		mixins: [mixin],
		data() {
			return {
				stationInfo:{}
			}
		},
		methods: {
			// 获取数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "stored-overview-central-information-overview-real", // 接口code
						"@station_id": localStorage.getItem("stationPStorageId") //  站点id 例如621025075751045445
					})
					.then((res) => {
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>
<style lang="scss">
    .pv-Device-box {
        margin: 12px;
        background: #FFFFFF;
        border-radius: 16px 16px 16px 16px;
        padding: 12px;
        .title {
            line-height: 32px;
            font-weight: bold;
            font-size: 16px;
            color: rgba(0,0,0,0.7);
        }
        .pv-list {
            margin-top: 16px;
            > div {
                height: 82px;
                margin-bottom: 8px;
                display: flex;
                .pv-list-img {
                    width: 82px;
                    background: #F7F8FA;
                }
                .pv-list-info {
                    flex: 1;
                    margin-left: 8px;
                    display: flex;
                    .pv-info-total {
                        width: 62px;
                        padding-top: 13px;
                        p {
                            color: rgba(0,0,0,0.7);
							overflow: hidden;
                        }
                        div {
                            font-size: 20px;
                            color: rgba(0,0,0,0.9);
							flex-shrink: 1;
                            line-height: 20px;
                            margin-top: 8px;
                            display: flex;
							overflow: hidden;
                            align-items: center;
                            font-family: TCloud Number, TCloud Number;
                            span {
                                font-size: 12px;
                                color: rgba(0,0,0,0.7);
                                font-family: PingFang SC, PingFang SC;
                            }
                        }
                    }
                    .pv-type-list {
                        flex: 1;
                        margin-left: 12px;
                        padding: 13px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
						overflow: hidden;
                        > div {
                            display: flex;
                            align-items: center;
							overflow: hidden;
                            div {
                                width: 3px;
                                height: 8px;
								overflow: hidden;
                                box-shadow: 0px 11px 12px 0px rgba(77,211,208,0.25);
                                border-radius: 11px 11px 11px 11px;
                            }
                            .charge {
                                background: #00ADB5;
                            }
                            .stationary {
                                background: #A9AEB8;
                            }
                            .discharge {
                                background: #FF9A2E;
                            }
                            .fault {
                                background: #F53F3F;
                            }
                            p {
                                font-weight: 400;
                                color: rgba(0,0,0,0.7);
                                margin: 0 8px 0 2px;
                            }
                            span {
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 800;
                            }
                        }
                    }
                }
            }
        }
    }
</style>