<template>
  <div id="app">
    <router-view/>
   <!-- <van-tabbar v-model="active" active-color="#00ADB5" inactive-color="#A9AEB8">
        <van-tabbar-item icon="apps-o">概览</van-tabbar-item>
        <van-tabbar-item icon="user">我的</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                active: 0
            }
        },
		beforeCreate() {
			// console.log(process, '99999999999999999')
		}
    }
</script>
<style lang="scss">
    html, body {
        width: 100%;
        height: 100%;
    }
    p {
        margin: 0;
        padding: 0;
    }
    * {
        box-sizing: border-box;
    }
    #app {
        width: 100%;
        height: 100%;
        overflow: hidden;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.9);
        line-height: 22px;
        font-style: normal;
        text-transform: none;
        background: #F7F8FA;
        box-sizing: border-box;
    }
    #app::-webkit-scrollbar {
        display: none;
    }
</style>
